import './App.css';
import { FaTelegramPlane, FaInfoCircle } from 'react-icons/fa'; // Telegram iconlari uchun

function App() {
    return (
        <div className='container'>
            <div className="content">
                <h1>Sayt tez orada ishga tushadi!</h1>
                <div className="links">
                    <a className='admin' href="https://t.me/sindorov_bekhzod">
                        <FaTelegramPlane className="icon" /> Admin
                    </a>
                    <a className='kanal' href="https://t.me/testmaqsad">
                        <FaInfoCircle className="icon" /> Sayt haqida yangiliklar
                    </a>
                </div>
            </div>
        </div>
    );
}

export default App;
